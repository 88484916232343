import React from "react";
import PropTypes from "prop-types";
import { StyleSheet, css } from "aphrodite";
import cn from "classnames";
import shortid from "shortid";
import Layout from "./Layout";
import Menu from "./Menu";
import Footer from "./Footer";

import PageLink from "../Link";

import pageInfos from "../../config/i18n/pagesInfos";
import Config from "../../Config";

const AlternativeLayout = ({
  children,
  title,
  subtitle,
  image,
  map,
  contact,
  breadcrumbLinks
}) => {
  return (
    <Layout>
      <Menu isDisplayFromHome={false} />
      <div
        className="site-hero_2 bg-fade"
        style={{
          backgroundPosition: "center",
          backgroundImage: "url(" + image + ")"
        }}
      >
        <div className="section-overlay" />
        <div className="page-title" />
      </div>
      {children}
      <Footer isDisplayFromHome={false} />
    </Layout>
  );
};

AlternativeLayout.propTypes = {};

AlternativeLayout.defaultProps = {};

export default AlternativeLayout;
